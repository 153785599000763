<template>
  <div>
    <div class="contentsWidth mx-auto mt-4 mb-3">
      <div class="">
        <p class="title mb-0">審査担当割当</p>
        <hr class="title">
      </div>
    </div>

    <div
      class="contentsWidth mx-auto my-3 notice-wrap">
      <p
        v-if="isOver"
        class="mb-0">この助成プログラムは審査が終了しているため、割当の変更はできません。
      </p>
      <p
        v-for="(msg, index) in errMsgs" :key="index"
        class="mb-0">{{msg}}
      </p>
    </div>

    <div class="contentsWidth mx-auto flex mb-3">
      <section class="left-box">
        <div class="filter-wrap">
          <div class="year-wrap">
            年度
            <b-form-select
              @change="getStageBunriList"
              :options="yearList"
              v-model="form.year">
              <template v-slot:first>
                <option :value="null">-- 選択してください。 --</option>
              </template>
            </b-form-select>
          </div>
          <div class="series-wrap">
            プログラム
            <b-form-select
              @change="getStageBunriList"
              :options="seriesList"
              v-model="form.series">
              <template v-slot:first>
                <option :value="null">-- 選択してください。 --</option>
              </template>
            </b-form-select>
          </div>
        </div>

        <div class="filter-wrap">
          <div class="stage-wrap">
            審査段階
            <b-form-select
              @change="getMarkerList"
              :options="stageList"
              v-model="form.stage"
              :disabled="setNoSeries">
              <template v-slot:first>
                <option :value="null">-- 選択してください。 --</option>
              </template>
            </b-form-select>
          </div>
          <div class="bunri-wrap absolute" v-if="hasBunri">
            文理区分
            <b-form-radio-group
              class="bunri-radio"
              @input="getMarkerList"
              :options="{ 1: '文', 2: '理' }"
              v-model="form.bunri">
            </b-form-radio-group>
          </div>
        </div>

        <perfect-scrollbar
          id="selected-scroll-wrap"
          class="w100 p-3 main-box left">
          <vue-draggable
            id="selected-wrap"
            :move="canMove"
            :list="selectedMarkerIdList"
            v-bind="dragOptions">
            <div
              @click.shift="checkShift(selecedMarkerId, 'left')"
              @click.exact="checkApp(selecedMarkerId)"
              v-for="(selecedMarkerId, index) in selectedMarkerIdList" :key="index"
              :class="[
                'item',
                markerData[selecedMarkerId].check ? 'checked' : '',
                markerData[selecedMarkerId].shift ? 'shift' : '',
              ]">
              {{markerData[selecedMarkerId].name}}
            </div>
          </vue-draggable>
        </perfect-scrollbar>

      </section>
      <section class="middle-box">
        <div class="arrow-wrap">
          <img
            @click="goRight"
            class="arrow-right"
            src="@/assets/svg/arrow-right.svg">
          <img
            v-show="canGoRight && !setNoSeries && !isOver"
            @click="goRight"
            class="arrow-right"
            src="@/assets/svg/arrow-right-blue.svg">
          <img
            @click="goLeft"
            class="arrow-left"
            src="@/assets/svg/arrow-left.svg">
          <img
            v-show="canGoLeft && !setNoSeries && !isOver"
            @click="goLeft"
            class="arrow-left"
            src="@/assets/svg/arrow-left-blue.svg">
        </div>
      </section>
      <section class="right-box">
        <div class="filter-input-wrap">
          <p class="mb-0">審査委員氏名</p>
          <b-form-input
            class="search-input"
            @input="initCheckShift"
            v-model="filter.name"
            placeholder="入力してください"/>
        </div>
        <div class="filter-input-wrap mt-1">
          <p class="mb-0">審査委員ふりがな</p>
          <b-form-input
            class="search-input"
            @input="initCheckShift"
            v-model="filter.name_kana"
            placeholder="入力してください"/>
        </div>

        <perfect-scrollbar
          class="w100 p-3 main-box right">
          <vue-draggable
            id="selectable-wrap"
            :move="canMove"
            :list="filterdSelectebleMarkerIdList"
            v-bind="dragOptions">
            <div
              v-for="(filterdSelectebleMarkerId, index) in filterdSelectebleMarkerIdList" :key="index"
              @click.shift="checkShift(filterdSelectebleMarkerId, 'right')"
              @click.exact="checkApp(filterdSelectebleMarkerId)"
              :class="[
                'item',
                markerData[filterdSelectebleMarkerId].check ? 'checked' : '',
                markerData[filterdSelectebleMarkerId].shift ? 'shift' : '',
              ]">
              {{markerData[filterdSelectebleMarkerId].name}}
            </div>
          </vue-draggable>
        </perfect-scrollbar>
      </section>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        @click="save"
        :disabled="setNoSeries || isOver"
        variant="primary"
        class="btn btn-lg bold ml-2">登録</b-button>
      <b-button
        @click="exportCsv()"
        :disabled="setNoYear"
        variant="primary"
        class="btn btn-lg bold ml-2">CSV出力</b-button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
// eslint-disable-next-line
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';

export default {
  name: 'cmsApplicationAssignEdit',
  components: {
    'vue-draggable': draggable,
  },
  data() {
    return {
      yearList: {},
      dbSeriesList: [],
      stageList: [],
      hasBunri: false,
      form: {
        year: null,
        series: null,
        stage: null,
        bunri: null,
      },

      apptype: {},
      stageData: {},
      markerList: [],
      selectedMarkerIdList: [],
      checkedMarkerIdList: [],
      // shiftやaltでの複数選択機能に使う、始点と終点でappIdを最大２個入れておく配列
      checkShiftMarkerIds: [],
      witchShift: null,
      filter: {
        name: '',
        name_kana: '',
      },
      markerData: {},
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      },
      errMsgs: [],
    };
  },
  computed: {
    seriesList() {
      const filterSeriesList = this.dbSeriesList.filter((data) => {
        return (this.form.year !== null && this.form.year < 2025) || data.id !== 9;
      });
      return filterSeriesList.map((series) => {
        if (this.form.year !== null) {
          if (this.form.year < 2025 && series.id === 2) {
            return { value: series.id, text: '調査研究助成' };
          }
          if (this.form.year < 2025 && series.id === 8) {
            return { value: series.id, text: '目的型調査研究助成' };
          }
        }

        return { value: series.id, text: series.name };
      });
    },
    selectableMarkerIdList() {
      const selectableMarkerIdList = [];
      Object.keys(this.markerData).forEach((key) => {
        if (this.selectedMarkerIdList.indexOf(this.markerData[key].id) === -1) {
          selectableMarkerIdList.push(this.markerData[key].id);
        }
      });
      return selectableMarkerIdList;
    },
    filterdSelectebleMarkerIdList() {
      let filterdSelectebleMarkerIdList = [];
      filterdSelectebleMarkerIdList = this.selectableMarkerIdList.filter((marketId) => {
        const okName = this.markerData[marketId].name.includes(this.filter.name);
        const okNameKana = this.markerData[marketId].name_kana.includes(this.filter.name_kana);
        return okName && okNameKana;
      });
      return filterdSelectebleMarkerIdList;
    },
    canGoLeft() {
      let result = false;
      this.selectableMarkerIdList.forEach((appId) => {
        if (this.markerData[appId].check) {
          result = true;
        }
      });
      return result;
    },
    canGoRight() {
      let result = false;
      this.selectedMarkerIdList.forEach((appId) => {
        if (this.markerData[appId].check) {
          result = true;
        }
      });
      return result;
    },
    isOver() {
      if (!this.apptype.result_announcement_datetime) {
        return false;
      }
      const now = moment();
      const endTime = moment(this.apptype.result_announcement_datetime);
      return now.isSameOrAfter(endTime);
    },
    setNoSeries() {
      return this.form.year === null || this.form.series === null;
    },
    setNoStageBunri() {
      if (this.hasBunri) {
        return this.form.stage === null || this.form.bunri === null;
      }
      return this.form.stage === null;
    },
    setNoYear() {
      return this.form.year === null;
    },
  },
  methods: {
    async exportCsv() {
      if (this.setNoYear) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      // n次のnを取得
      const params = {
        apptypeId: this.apptype.id,
        stageId: this.form.stage,
        bunri: this.form.bunri,
        year: this.form.year,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/applications/assign/export/csv', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      let fileName = `${ymd}_${this.form.year}`;
      if (this.form.series) {
        const apptype = this.seriesList.find(data => data.value === this.form.series);
        fileName += `_${apptype.text}`;
      }
      fileName += '_審査担当.csv';
      download.csvUtf(response.data, fileName);
    },
    async fetchInitDate() {
      const response = await api.send('/api/cms/applications/assign/edit', {})
        .catch((err) => {
          console.error(err);
        });
      if (!response) {
        await this.alert('データを取得できませんでした。');
        this.$router.push({ path: 'cms/applications/assign/list' });
        return;
      }
      this.yearList = response.data.yearList;
      this.dbSeriesList = response.data.seriesList;
    },
    // 年度とプログラムの選択で、ステージの取得と文理区分の表示を行う
    async getStageBunriList() {
      if (this.form.year >= 2025 && this.form.series === 9) {
        this.form.series = null;
      }
      this.form.stage = null;
      this.form.bunri = null;
      this.initCheckState();
      this.initCheckShift();
      this.markerData = {};
      this.selectedMarkerIdList = [];

      if (this.setNoSeries) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        year: this.form.year,
        seriesId: this.form.series,
      };
      const response = await api.send('/api/cms/applications/assign/stageList', param)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('データを取得できませんでした。');
        return;
      }
      this.stageList = this.makeStageSelect(response.data.stageList);
      this.hasBunri = response.data.hasBunri;
      this.apptype = response.data.apptype;
    },
    async getMarkerList() {
      this.initCheckState();
      this.initCheckShift();
      this.markerData = {};
      this.selectedMarkerIdList = [];
      if (this.setNoStageBunri) {
        return;
      }
      this.errMsgs = [];
      this.$store.dispatch('page/onWaiting');
      const param = {
        apptypeId: this.apptype.id,
        stageId: this.form.stage,
        bunri: this.form.bunri,
      };
      const response = await api.send('/api/cms/applications/assign/markerList', param)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('データを取得できませんでした。');
        return;
      }
      this.markerData = response.data.markerData;
      this.selectedMarkerIdList = response.data.assignMarkerIdList;
    },
    async save() {
      this.errMsgs = [];
      this.$store.dispatch('page/onWaiting');
      const param = {
        stageId: this.form.stage,
        selectedMarkerIdList: this.selectedMarkerIdList,
        bunri: this.form.bunri,
      };
      const response = await api.send('/api/cms/applications/assign/save', param)
        .catch(async (err) => {
          // 登録しましたのアラートが背景ありだと妙なスクロールが起きるので、Offwaitingを別で設定
          this.$store.dispatch('page/offWaiting');
          await this.alert('登録に失敗しました。');
          this.errMsgs = err.response.data.messages;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      await this.alert('登録しました。');
    },
    // falseを返すとvue-draggableのdrag操作を元に戻す
    canMove() {
      return !this.setNoSeries && !this.isOver;
    },
    // 審査段階のセレクトボックスの作成
    makeStageSelect(dbStageList) {
      const stageList = [];
      dbStageList.forEach((stage) => {
        const data = {
          value: stage.id,
          text: `${stage.stage}次`,
        };
        stageList.push(data);
      });
      return stageList;
    },
    checkApp(appId) {
      this.initCheckShift();
      const index = this.checkedMarkerIdList.indexOf(appId);
      if (index === -1) {
        this.checkedMarkerIdList.push(appId);
        this.$set(this.markerData[appId], 'check', true);
      } else {
        this.checkedMarkerIdList.splice(index, 1);
        this.$set(this.markerData[appId], 'check', false);
      }
    },
    checkShift(appId, which) {
      // const index = this.checkedMarkerIdList.indexOf(appId);
      // 左右どちらを操作するか保存。前回と異なれば選択状態初期化
      if (this.witchShift !== which) {
        this.initCheckShift();
      }
      this.witchShift = which;
      let targetList = [];
      if (which === 'left') {
        targetList = this.selectedMarkerIdList;
      } else {
        targetList = this.filterdSelectebleMarkerIdList;
      }
      // すでに選択済みの場合は解除して終了
      const checkIndex = this.checkShiftMarkerIds.indexOf(appId);
      if (checkIndex !== -1) {
        this.$set(this.markerData[appId], 'shift', false);
        this.checkShiftMarkerIds.splice(checkIndex, 1);
        return;
      }
      const count = this.checkShiftMarkerIds.length;
      // すでに2つ以上選択されていたら1つだけにする（始点のみにする）
      if (count >= 2) {
        this.checkShiftMarkerIds.forEach((removeAppId, index) => {
          if (index > 0) {
            this.$set(this.markerData[removeAppId], 'shift', false);
          }
        });
        this.checkShiftMarkerIds = [this.checkShiftMarkerIds[0]];
      }
      // クリックされたものをshift選択状態にする
      this.checkShiftMarkerIds.push(appId);
      this.$set(this.markerData[appId], 'shift', true);
      // 2つ揃っていない場合は処理終了
      if (this.checkShiftMarkerIds.length < 2) {
        return;
      }
      const val1Index = targetList.indexOf(this.checkShiftMarkerIds[0]);
      const val2Index = targetList.indexOf(this.checkShiftMarkerIds[1]);
      if (val1Index === -1 || val2Index === -1) {
        console.error('error');
        this.checkShiftMarkerIds = [];
        return;
      }
      // keyのインデックスを小さい順の配列にする。
      const keyIndexList = val1Index < val2Index ? [val1Index, val2Index] : [val2Index, val1Index];
      // 小さいインデックスから大きいインデックスまでを選択状態にし、ついでにshift選択状態を解除する
      for (let i = keyIndexList[0]; i <= keyIndexList[1]; i += 1) {
        const targetId = targetList[i];
        this.$set(this.markerData[targetId], 'check', true);
        this.$set(this.markerData[targetId], 'shift', false);
        const index = this.checkedMarkerIdList.indexOf(targetId);
        // 通常選択データ上も選択状態にする
        if (index === -1) {
          this.checkedMarkerIdList.push(targetId);
        }
      }
      // shift選択のデータ側も初期化
      this.checkShiftMarkerIds = [];
    },
    goLeft() {
      if (this.setNoSeries || this.isOver || !this.canGoLeft) {
        return;
      }
      this.checkedMarkerIdList.forEach((markerId) => {
        const selectedIndex = this.selectedMarkerIdList.indexOf(markerId);
        if (selectedIndex === -1) {
          this.selectedMarkerIdList.push(markerId);
        }
        this.$set(this.markerData[markerId], 'check', false);
      });
      this.checkedMarkerIdList = [];
    },
    goRight() {
      if (this.setNoSeries || this.isOver || !this.canGoRight) {
        return;
      }
      this.checkedMarkerIdList.forEach((markerId) => {
        const selectedIndex = this.selectedMarkerIdList.indexOf(markerId);
        if (selectedIndex !== -1) {
          this.selectedMarkerIdList.splice(selectedIndex, 1);
        }
        this.$set(this.markerData[markerId], 'check', false);
      });
      this.checkedMarkerIdList = [];
    },
    // 通常の選択状態の初期化
    initCheckState() {
      Object.keys(this.markerData).forEach((markerId) => {
        this.$set(this.markerData[markerId], 'check', false);
      });
      this.checkedMarkerIdList = [];
    },
    // shift選択状態の初期化
    initCheckShift() {
      this.checkShiftMarkerIds.forEach((id) => {
        this.$set(this.markerData[id], 'shift', false);
      });
      this.checkShiftMarkerIds = [];
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    await this.fetchInitDate();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  .bunri-radio>.custom-radio>.custom-control-label {
    font-size: 16px;
    margin-right: 25px;
    margin-left: 5px;
  }
  .bunri-radio>.custom-radio>.custom-control-label::before {
    margin-top: -2px;
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
  }
  .bunri-radio>.custom-radio>.custom-control-label::after {
    margin-top: -2px;
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
  }
</style>

<style scoped>
  select, input {
    height: 50px;
  }
  input.search-input {
    height: 50px;
  }
  .filter-wrap {
    width: 620px;
    margin-bottom: 10px;
  }
  .year-wrap, .stage-wrap, .bunri-wrap {
    width: 200px;
    display: inline-block;
    margin-right: 20px;
  }
  .series-wrap {
    width: 400px;
    display: inline-block;
  }
  .bunri-radio {
    padding-top: 13px;
  }

  .left-box {
    width: 620px;
    height: 100%;
    border-width: 1px;
    border-color: #CCCCCC;
  }

  .middle-box {
    width: 60px;
    height: 100%;
  }
  .arrow-wrap {
    position: relative;
  }
  .arrow-left {
    position: absolute;
    top: 370px;
    left: 9px;
    cursor: pointer;
  }
  .arrow-right {
    position: absolute;
    top: 305px;
    left: 9px;
    cursor: pointer;
  }

  .arrow-left.disabled, .arrow-right.disabled {
    cursor: not-allowed;
  }


  .right-box {
    width: 400px;
    height: 100%;
  }

  .main-box {
    background-color: #fff;
    border:solid 1px #CCC;
  }

  .main-box.left {
    height: 504px;
    margin-top: 20px;
  }

  .main-box.right {
    height: 504px;
    margin-top: 20px;
  }

  .filter-input-wrap {
    margin-bottom: 10px;
  }

  .item {
    width: 100%;
    height: 40px;
    background-color: #E6E6E6;
    border-radius: 25px;
    padding: 9px 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 7px 0;
    cursor: pointer;
  }

  .item.checked {
    background-color: rgba(18, 40, 137, 0.2);
  }

  .item.shift {
    border: solid 1px rgba(18, 40, 137);
  }

  #selected-wrap {
    min-height: 100%;
  }
  #selectable-wrap {
    min-height: 100%;
  }

  #selected-scroll-wrap.disable {
    background-color: #F3F3F3;
    cursor: not-allowed;
  }

  .assign_number {
    width: 188px;
  }

  .notice-wrap {
    color: #D56E6E;
  }
</style>
